/* eslint-disable jsx-a11y/aria-role */
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type {
  FC,
  ImgHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '@kuna-pay/ui/icons';
import picture from '@kuna-pay/ui/images/verification-pending.png';
import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import type { AnalyticsProperties } from '@kuna-pay/merchant/shared/analytics';
import { routes } from '@kuna-pay/merchant/shared/router';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import { $$session } from '../../model';
import { CanAccess } from '../has-access';
import styles from './verification-guard.module.scss';

type VerificationGuardProps = PropsWithChildren<{
  from: AnalyticsProperties.VerificationPageViewedEventFrom;

  pending?: ReactNode;

  unverified: ReactNode;

  Layout?: FC<PropsWithChildren>;
}>;

const VerificationGuard = ({
  from,
  pending,
  children,
  unverified,
  Layout = Fragment,
}: VerificationGuardProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'entities.session.ui.verification-guard',
  });

  const [isVerified, isPending] = useUnit([
    $$session.$$jwt.$$company.$isVerified,
    $$session.$$jwt.$$company.$isPendingVerification,
  ]);

  if (isVerified) {
    return <>{children}</>;
  }

  if (isPending) {
    return pending ? (
      <Layout>{pending}</Layout>
    ) : (
      <Layout>
        <Paper className={styles.pending}>
          <img
            className={styles.pendingPicture}
            src={picture}
            height={180}
            alt=''
          />

          <Typography className={styles.pendingTitle} variant='h7'>
            {t('pending.title')}
          </Typography>

          <Typography className={styles.pendingSubtitle} variant='subtitle5'>
            {t('pending.subtitle')}
          </Typography>
        </Paper>
      </Layout>
    );
  }

  return (
    <Layout>
      <MainLayout.MFANotEnabledAlert />

      <Paper className={styles.root}>
        {unverified}

        <CanAccess.Company.Role
          role={[JWTCompanyRole.Owner, JWTCompanyRole.Admin]}
        >
          <Link to={routes.auth.verification.start.withQuery({ from })}>
            <Button
              variant='contained'
              color='primary'
              size='lg'
              end={<ArrowRightIcon />}
            >
              {t('unverified.action')}
            </Button>
          </Link>
        </CanAccess.Company.Role>
      </Paper>
    </Layout>
  );
};

VerificationGuard.Title = ({
  className,
  ...props
}: Partial<TypographyProps>) => (
  <Typography
    className={clsx(styles.title, className)}
    variant='h7'
    center
    {...props}
  />
);

VerificationGuard.Img = ({
  className,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) => (
  <img className={clsx(styles.img, className)} alt='' {...props} />
);

VerificationGuard.Description = ({
  className,
  ...props
}: Partial<TypographyProps>) => (
  <Typography
    className={clsx(styles.description, className)}
    variant='body3'
    center
    {...props}
  />
);

export { VerificationGuard };
