/* eslint-disable @typescript-eslint/lines-between-class-members */
import { combine, createEvent, type Store } from 'effector';

import { listen, setState } from '@kuna-pay/utils/effector';
import { StoreProperty } from '@kuna-pay/utils/effector/property';
import type { AuthTokensOutput } from '@kuna-pay/core/generated/public/graphql';

import type { IUseCase } from '../../lib';
import type { APIPersistService } from '../persist';
import type { IKunaPayAuthService } from './interface.auth-service';

class ApiTokensService {
  public readonly onTokenUpdated = createEvent();
  public readonly onTokenCleared = createEvent();
  public readonly onLoggedOut = createEvent();

  private readonly $$accessToken: StoreProperty<string | null>;
  private readonly $$refreshToken: StoreProperty<string | null>;

  public readonly $tokens: Store<{
    accessToken: string | null;
    refreshToken: string | null;
  }>;
  public readonly $accessToken: Store<string | null>;
  public readonly $refreshToken: Store<string | null>;

  public constructor(
    public readonly accessTokenPersistService: APIPersistService,
    public readonly refreshTokenPersistService: APIPersistService,

    private readonly refreshTokenUseCase: IUseCase<string, AuthTokensOutput>
  ) {
    this.$$accessToken = StoreProperty.create<string | null>(
      accessTokenPersistService.read()
    );

    this.$$refreshToken = StoreProperty.create<string | null>(
      refreshTokenPersistService.read()
    );

    this.$accessToken = this.$$accessToken.$store;
    this.$refreshToken = this.$$refreshToken.$store;

    this.$tokens = combine({
      accessToken: this.$accessToken,
      refreshToken: this.$refreshToken,
    });

    listen({
      /**
       * Listen for the token changes
       *
       * Note: this event is not triggered on the initial load with persist
       * because of sync nature of effector-persist which doesn't trigger updates
       * without explicit event
       */
      clock: this.$$accessToken.$store.updates,
      handler: (token) => {
        if (token) {
          this.onTokenUpdated();
        } else {
          this.onTokenCleared();
        }
      },
    });

    accessTokenPersistService.connect(this.$$accessToken.$store);
    refreshTokenPersistService.connect(this.$$refreshToken.$store);
  }

  public refreshTokens = async () => {
    if (!this.$$refreshToken.value) return Promise.reject();

    const tokens = await this.refreshTokenUseCase.execute(
      this.$$refreshToken.value
    );

    this.updateTokens(tokens);

    return tokens;
  };

  public updateTokens = ({
    accessToken,
    refreshToken,
  }: Pick<AuthTokensOutput, 'accessToken' | 'refreshToken'>) => {
    setState(this.$$accessToken.$store, accessToken);
    setState(this.$$refreshToken.$store, refreshToken);
  };

  public clearTokens() {
    setState(this.$$accessToken.$store, null);
    setState(this.$$refreshToken.$store, null);
  }

  public createAuthServiceForGQLClient = (): IKunaPayAuthService => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return {
      refresh: async () => {
        await this.refreshTokens();
      },
      logout: async () => {
        self.clearTokens();

        self.onLoggedOut();
      },
      addHeaders: (headers: Record<string, string>) => {
        if (self.$$accessToken.value) {
          headers['jwt-token'] = self.$$accessToken.value;
        }
      },
    };
  };
}

export { ApiTokensService };
